import { makeAutoObservable, action, reaction } from "mobx";
import { isPlainObject } from "lodash";

import { routerStore } from "./../";
import { initialUserInfo, syncUserInfo } from "./SyncUserInfo";
import { LOCALSTORAGE_KEYS } from "@Constants/index";
import request from "@Utils/Request";

export class AuthStore {
	/**
	 * User Info
	 *
	 * @type {IAuthStore.UserInfo}
	 * @memberof AuthStore
	 */
	userInfo: IAuthStore.UserInfo = initialUserInfo;

	constructor() {
		makeAutoObservable(this);
		reaction(() => this.userInfo, syncUserInfo);
	}

	@action
	login = async (params: IAuthStore.LoginParams) => {
		const { data } = await request.post<IAuthStore.UserInfo>("auth/login", params);
		this.setUserInfo(isPlainObject(data) ? data : {});
		localStorage.setItem(LOCALSTORAGE_KEYS.USERINFO, JSON.stringify(data));
		routerStore.replace("/");
	};

	logout = () => {
		this.setUserInfo({});
		localStorage.removeItem(LOCALSTORAGE_KEYS.USERINFO);
		routerStore.replace("/login");
	};

	/**
	 * Initialize user information
	 *
	 * @memberof AuthStore
	 */
	@action
	setUserInfo = (userInfo: IAuthStore.UserInfo): IAuthStore.UserInfo => {
		this.userInfo = userInfo;
		return userInfo;
	};
}

export default new AuthStore();
