export enum COOKIE_KEYS {
	LANG = "lang",
}

export enum LOCALSTORAGE_KEYS {
	USERINFO = "userInfo",
	NAV_OPEN_KEYS = "navOpenKeys",
	SIDE_BAR_THEME = "sideBarTheme",
	SIDE_BAR_COLLAPSED = "sideBarCollapsed",
	// about socket
	SOCKET_URL = "socketUrl",
	SOCKET_TYPE = "socketType",
	SOCKET_IO_EVENTS = "_socketIOEvents",
	DATA_FORMAT = "dataFormat",
	NOT_SUPPORT_POLLING = "notSupportPolling",
}

export const LOGIN_CATEGORY = ["user", "admin"];

export const GITHUB_LINK = "https://github.com/YDJ-FE";
