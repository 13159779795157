import { makeAutoObservable, observable, runInAction } from "mobx";
import { TablePaginationConfig } from "antd/lib/table";

import request from "@Utils/Request";
import service from "@Services/Staff/StaffService";

export class StaffStore {
	constructor() {
		makeAutoObservable(this, { staff: observable.ref });
	}
	/**
	 * 加载用户列表时的loading
	 *
	 * @memberof StaffStore
	 */
	getStaffloading = false;
	/**
	 * 用户列表
	 *
	 * @type {IStaffStore.IStaff[]}
	 * @memberof StaffStore
	 */
	staff: IStaffStore.IStaff[] = [];
	/**
	 * table pageIndex
	 *
	 * @memberof StaffStore
	 */
	pageIndex = 1;
	/**
	 * table pageSize
	 *
	 * @memberof StaffStore
	 */
	pageSize = 30;
	/**
	 * staffs total
	 *
	 * @memberof StaffStore
	 */
	total = 0;

	/**
	 * 加载用户列表
	 *
	 * @memberof StaffStore
	 */
	getStaff = async () => {
		this.getStaffloading = true;
		try {
			/* const { data } = await request.get('staff', {
                params: { pageIndex: this.pageIndex, pageSize: this.pageSize }
			}) */
			const data = await service.getStaff();
			runInAction(() => {
				this.staff = data.staff;
				this.total = data.total;
			});
		} finally {
			runInAction(() => {
				this.getStaffloading = false;
			});
		}
	};

	createStaff = async (staff: IStaffStore.IStaff) => {
		await request.post("staff/create", staff);
	};

	modifyStaff = async (staff: IStaffStore.IStaff) => {
		await request.post("staff/modify", staff);
	};

	deleteStaff = async (_id: string) => {
		await request.post("staff/delete", { _id });
		this.getStaff();
	};

	changePageIndex = (pageIndex: number) => {
		this.pageIndex = pageIndex;
		this.getStaff();
	};

	changePageSize = (pageSize: number) => {
		this.pageSize = pageSize;
		this.getStaff();
	};

	handleTableChange = ({ current, pageSize }: TablePaginationConfig) => {
		if (current !== this.pageIndex) {
			this.changePageIndex(current);
		}
		if (pageSize !== this.pageSize) {
			this.changePageSize(pageSize);
		}
	};
}

export default new StaffStore();
