import BaseService from "@Services/Base/BaseService";
import { IStaff } from "@Store/StaffStore/type";
import request from "@Utils/Request";

class StaffService extends BaseService {
	baseUrl = "mocks/";

	async getStaff(): Promise<{ staff: IStaff[]; total: number }> {
		const { data } = await request.get<{ staff: IStaff[]; total: number }>(this.baseUrl + "Staff.json");
		return data;
	}
}

export default new StaffService();
