import React, { createContext, ReactElement } from "react";
import { Observer } from "mobx-react";

import * as store from "@Store/index";

interface ChildrenProps<T> {
	children: (value: T) => ReactElement<any>;
}

export const RootContext = createContext<IStore>(null);

/**
 * Included Observer
 * @param param0
 */
export const RootConsumer = ({ children }: ChildrenProps<IStore>) => <Observer>{() => children(store)}</Observer>;

const Provider: React.FC = ({ children }) => {
	return <RootContext.Provider value={{ ...store }}>{children}</RootContext.Provider>;
};

export default Provider;
