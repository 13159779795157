import "./index.scss";

import React from "react";
import ReactDOM from "react-dom";
import { configure } from "mobx";

import registerServiceWorker from "./sw";
import App from "@Shared/App";
import catchUnhandledRejection from "./ErrorHandler";

registerServiceWorker();
configure({ enforceActions: "observed" });
catchUnhandledRejection();

const render = (Component: React.ComponentType) => {
	ReactDOM.render(<Component />, document.getElementById("app"));
};

render(App);
