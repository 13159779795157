import axios, { AxiosRequestConfig } from "axios";
import { get } from "lodash";
import qs from "qs";
import { Modal } from "antd";

import { routerStore } from "@Store/index";
import { userInfo } from "@Store/AuthStore/SyncUserInfo";

const TIMEOUT = 2 * 60000;

// if you want another config, create one!!
const DEFAULTCONFIG: AxiosRequestConfig = {
	baseURL: process.env.BASEURL,
	timeout: TIMEOUT,
	paramsSerializer: (params) => {
		return qs.stringify(params, {
			encode: false,
		});
	},
};

const NO_NEED_AUTH_URLS = ["auth/login"];

function getAxiosInstance() {
	const instance = axios.create(DEFAULTCONFIG);
	instance.interceptors.request.use(
		(config) => {
			if (!NO_NEED_AUTH_URLS.includes(config.url) && userInfo?.token) {
				config.headers["Authorization"] = `Bearer ${userInfo.token}`;
			}
			return config;
		},
		(error) => Promise.reject(error)
	);
	instance.interceptors.response.use(
		(response) => response,
		(error) => {
			const errMsg = get(error, "response.data.message");
			if (errMsg === "invalid token") {
				routerStore.replace("/login");
			}
			const errDetails = get(error, "response.data.details");
			if (!!errMsg && !!errDetails) {
				Modal.error({
					title: errMsg,
					content: errDetails,
				});
			} else if (!!errMsg) {
				Modal.error({
					title: "Error",
					content: errMsg,
				});
			} else {
				Modal.error({ content: "Unknown Error" });
			}

			return Promise.reject(error);
		}
	);

	return instance;
}

export default getAxiosInstance();
